
import { Vue, Component, Prop } from 'vue-property-decorator';
import IconBreadcrumb from '@/components/icons/IconBreadcrumb.vue';

@Component({
  components: {
    IconBreadcrumb,
  },
})
export default class FaqPage extends Vue {
  @Prop({ default: '' }) accordion!: Record<string, unknown>;
  @Prop({ default: false }) isAccordionShown!: boolean;

  start(element: any): void {
    // eslint-disable-next-line
    element.style.height = `${element.scrollHeight}px`;
  }
  end(element: any): void {
    // eslint-disable-next-line
    element.style.height = '';
  }
}
