const faqQuestions = [
  {
    question: 'Why do you need to know your credit scores as a small business owner?',
    answer:
      'Small business owners need to know their credit scores because your business’s growth opportunities depend on them. Would you like your business to upgrade its machinery? Access working capital to fulfill a large order? Work with a new supplier on good terms? Your credit score is critical for all of these purposes. Knowing your credit score now, can help you understand what improvements you need to make in order for your business to reach its full potential.',
  },
  {
    question: 'How much does it cost to use CreditPush? How does CreditPush make money without selling user data?',
    answer:
      "Using CreditPush is absolutely free and we never sell your data. As you take actions through CreditPush and make better business decisions, your credit score will increase. As a result, we will be able to offer you quality personalized credit products that offer real value to your small business - like interest savings or better rewards. When you successfully take advantage of these offers, we are compensated by the associated financial institution. Let's be successful together.",
  },
  {
    question:
      'Why do small business owners care about personal credit scores and business credit scores? Isn’t the business credit score enough?',
    answer:
      'Banks and other credit institutions often evaluate small business owners’ personal credit score as well as their business credit score. If the business has a limited operating history, the personal credit history is often considered in addition to business credit history. Business owners may even be asked to give personal guarantees on business liabilities, in which case the personal credit score is heavily relied upon in making the lending decision.',
  },
  {
    question: 'What size business benefits from CreditPush?',
    answer:
      'CreditPush is designed with small businesses in mind - those that have less than $5 million in revenue and less than 25 employees. However, businesses of any size that need free credit boost tools can benefit from using CreditPush.',
  },
  {
    question: 'What if my small business doesn’t have a credit history yet?',
    answer:
      'If your small business does not have a credit history yet, then it is already ahead of businesses that have poor credit histories! You can use CreditPush to build a strong credit history, from day 1. ',
  },
  {
    question: 'How can CreditPush help my small business get a loan?',
    answer:
      'CreditPush will give you actionable insights to help your credit score improve, making any credit application more likely to be approved. In addition, CreditPush gives you easy access to a wide range of high quality credit products tailored to your small business’s credit needs. We only share products that bring your small business real value, such as interest savings. There are multiple ways to reach your credit goals using CreditPush!',
  },
  {
    question: 'How does CreditPush offer my small business personalized credit products and solutions?',
    answer:
      'We will proactively match your small business’s credit to great credit products that can help your small business meet its goals. You can also make specific loan requests, and we will then send your request to multiple different lenders to get you the most competitive solution. You enter an agreement directly with lenders if you decide to use one of these products - CreditPush is not a lender. Working with CreditPush to boost your credit score (for free!) is critical to accessing the best credit opportunities from these providers.',
  },
  {
    question: 'How does CreditPush access my credit scores?',
    answer:
      'We get your credit scores directly from major credit agencies. These agencies include Experian, Creditsafe, and Dan & Bradstreet. CreditPush is not a credit bureau or a credit reporting agency. We do not directly give or receive credit information from your creditors.',
  },
  {
    question: 'How often can I check my credit scores for free?',
    answer:
      'You can check your credit scores as often as you would like, and it is always free. You should check your credit score often, as changes may need your attention. Plus, you will enjoy the feeling of accomplishment that you get watching your credit score grow! The time you spend on the CreditPush platform will pay off.',
  },
  {
    question: 'Why should I connect my business apps to CreditPush?',
    answer:
      'You can supercharge credit improvement by connecting your favorite business apps. Credit Push offers 150+ app integrations, like CRM, social media, accounting, payroll, and marketing. Credit bureaus get a robust understanding of your credit profile with this data; your credit score could go up! This NEVER makes your credit score go down. Furthermore, connecting your favorite apps gives you access to a deeper analysis of your small business. For instance, you can understand your business’s overall financial health and plan for the future, through cash flow forecasts.',
  },
  {
    question: 'Can using CreditPush damage my credit scores?',
    answer:
      "Accessing credit scores through CreditPush CAN'T HURT YOUR SCORES. CreditPush accesses them through soft pulls, which means that they do not damage your credit. Hard pulls, which lenders may do when finalizing a loan or other credit product, are the type of access that affects your credit scores. CreditPush does not do hard pulls.",
  },
  {
    question: 'Is my data safe with CreditPush? ',
    answer:
      'Yes, CreditPush’s top priority is the safety of your data. We use 128-bit encryption to protect your data and data transmission.',
  },
];

export default faqQuestions;
