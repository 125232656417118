
import { Vue, Component } from 'vue-property-decorator';
import Accordion from '@/components/creditpushMain/FaqPage/Accordion.vue';
import faqQuestions from '@/texts/faqTexts';

@Component({
  components: { Accordion },
})
export default class FaqPage extends Vue {
  questions = faqQuestions;
  active = null;

  setActive(index: any): void {
    if (this.active === index) this.active = null;
    else this.active = index;
  }
}
